/*
 * 一覧・詳細画面(PC)共通
*/
.paint-content {
    width: 100%;
    min-width: 950px;
    height: 100%;       /* スクロール時もくじ固定用 */
    min-height: 800px;
    background-color: rgb(224 219 176);
    position: static;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: center;
    
}

.paint-content > div {
    width: 100%;
    margin-top: 85px;
    overflow-y: auto;
    padding: 0px 55px;
    display: flex;
    justify-content: center;
}

.paint-content > div > div {
    width: 90%;
}

.paint-masonry-link {
    text-decoration:none;
}

.paint-masonry-parent {
    background-color: rgba(255, 252, 224, 0.81);
    margin: 10px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.paint-masonry-img {
    width: 90%;
    display: block;
    margin-top: 15px;
}

.paint-mansory-tag {
    width: 90%;
    margin: 0px 5px;
}

.paint-mansory-tag > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    z-index: 1;
    list-style: none;
    padding: 0;
}

.paint-mansory-tag > ul > li{
    font-size: 13px;
    padding: 1px 8px;
    margin-right: 10px;
    background-color: rgb(233, 232, 232);
    color: rgb(87, 86, 86);
    text-align: center;
    border-radius: 3px;
}

.paint-mansory-datetime {
    width: 90%;
    color: #797979;
    font-size: 15px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row-reverse;
}


/*
 * ウィンドウ内ウィンドウ(モーダル)用
 */
/* モーダル表示のアニメーション用 */
.ReactModal__Content {
    opacity: 0;
}
/* モーダル表示のアニメーション用 */
.ReactModal__Content--after-open {
    opacity: 1;
}
/* モーダル表示のアニメーション用 */
.ReactModal__Content--before-close {
    opacity: 0;
}

.paint-modal-parent-850-over {
    width: 100%;
    height: 100%;
    display: flex;
}

.paint-modal-parent-850-under {
    width: 100%;
    height: 100%;
}

.paint-modal-img-parent-850-over {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.paint-modal-img-parent-850-under {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.paint-modal-img-parent-850-over > img {
    height: auto;
    max-height: 100%;
    width: fit-content;
    max-width: 100%;
}

.paint-modal-img-parent-850-under > img {
    height: auto;
    max-height: 100%;
    width: fit-content;
    max-width: 100%;
}

.paint-modal-tag-ymd-parent-850-over {
    width: 30%;
    padding-left: 30px;
}

.paint-modal-tag-ymd-parent-850-under {
    /*
    width: 100%;
    padding-left: 30px;
    */
}

.paint-modal-tag-ymd-parent-850-over > hr{
    width: 28%;
    position: absolute;
    bottom: 40px;
    background-color: #a5a5a5;
    height: 1px;
    border: 0px;
}

.paint-modal-tag-ymd-parent-850-under > hr{
    position: absolute;
    bottom: 40px;
    background-color: #a5a5a5;
    height: 1px;
    border: 0px;
    left: 10px;
    right: 10px;
}

.paint-modal-ymd-parent-850-over {
    bottom: 0;
    position: absolute;
    right: 0;
    margin: 15px 18px;
    color: #797979;
    font-size: 15px;
}

.paint-modal-ymd-parent-850-under {
    bottom: 0;
    position: absolute;
    right: 15px;
    margin: 15px 18px;
    color: #797979;
    font-size: 15px;
}
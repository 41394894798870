/*
 * NotFound画面用
 */

/* コンテンツ全体 */
.error-content{
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;         /* ヘッダーの後ろに重ねて表示 */
    display: flex;              /* 水平方向の中央表示 */
    align-items: center;        /* 水平方向の中央表示 */
    justify-content: center;    /* 垂直方向の中央表示 */   
    flex-direction: column;     /* 画像と文字を2行に分けて表示 */
    overflow: hidden;           /* スクロール禁止 */
    z-index: 1;                 /* ヘッダーより後ろに描画 */
}

/* エラー画像 (共通) */
.error-content > img {
    width: 100%;
    height: 100%;
    object-fit: cover;          /* 画面の縦横比で小さい方に合わせて画面全体表示 */
    object-position: top;       /* 図が見切れる場合は、下から削るようにする */
    position: absolute;         /* 配置固定用 */
    top: 0;                     /* 配置固定用 */
    left: 0;                    /* 配置固定用 */
}

/* エラー画像 (PC) */
.error-content-img-pc {
    width: 45%;
    
}

/* エラー画像 (SP) */
.error-content-img-sp {
    width: 90%;
}

/* エラーメッセージ */
.error-content > p {
    font-size: 20px;
}

/* コメント用フォントの追加 */
@font-face {
    font-family: 'bestone';
    src: url('../fonts/BestTen-CRT.otf') format('opentype');
  }

/* TOP画面のテキスト 外枠(PC) */
.error-content > div {
    position: absolute;
    visibility: hidden;
    background-color: #e9ececd1;
    border: solid 6px #20c1c7;
    border-radius: 15px;
}

span {
    text-align: left;
}

/* TOP画面のテキスト(PC) */
/*
p {
    font-family: 'bestone';
    font-size: 23px;
    color: #525252;
    margin-left: 20px;
    margin-right: 20px;
}
*/
#error-content > p {
    font-family: 'bestone';
    font-size: 23px;
    color: #525252;
    margin-left: 20px;
    margin-right: 20px;
}
/*
 * 一覧・詳細画面(PC)共通
*/
    .system-content {
        width: 100%;
        min-width: 950px;
        height: 100%;       /* スクロール時もくじ固定用 */
        min-height: 800px;
        background-color: rgb(224 219 176);
        position: static;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: center;
        
    }

/*
 * 一覧画面(PC)
*/
    .report-list-area {
        width: 100%;
        margin-top: 85px;
        overflow-y: auto;
        justify-content: center;
        display: grid;
    }

    .report-list-area > ul {
        max-width: 1500px;
        list-style-type: none!important;
        padding-left:0;
        justify-items: center;
        box-sizing: border-box; /* リストの幅をListの高さに合わせる */
        overflow-y: clip;
        margin-top: 30px;
    }

    .report-list-area > ul > li {
        width: 80%;
        height: 260px;
        padding-bottom: 30px;
    }

    .report-list-area > ul > li > a{
        text-decoration: none;
    }

    .system-report-link {
        height: 100%;
        padding: 0px 30px;
    }

    .system-report-link > div {
        height: 100%;
        margin: 0px 5vw;
        padding: 5px 10px;
        background-color: rgba(255, 252, 224, 0.81);
        transition: 0.15s;
    }

    .system-report-link > div:hover{
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        transform: translateY(-3px);
        cursor: pointer;
    }

    /* 
    * 各記事のスタイル : 開始
    */
    .system-report-top-common {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        font-family: inherit;
        color: #797979;
    }

    .system-report-top-common > span {
        margin: 6px 10px;
    }

    .system-report-date {
        float: left;
    }

    .system-report-view {
        float: right;
    }

    .system-report-title {
        margin: 3px 10px;
        font-size: 20px;
        color: #444444;
    }

    .system-report-horizon {
        border-color: rgb(160 156 156);
        border-width: 1px;
        border-style: solid;
        margin: 0px 5px;
    }

    .system-report-bottom-common {
        height: 200px;
        display: flex;
    }

    .system-report-preview {
        height: 85%;
        margin:  10px 10px;
        width: 280px;
        border: 1px solid;
        border-color: rgba(0, 0, 0, 0);
        border-radius: 10px;
        object-fit: cover;          /* 画面の縦横比で小さい方に合わせて画面全体表示 */
        object-position: center;       /* 図が見切れる場合は、中心を残す */
    }

    .system-report-content-preview {
        width: calc(100% - 290px);
    }

    .system-report-content-preview > div {
        height: 13%;
        
    }

    .system-report-tag {
        width: 100%;
        margin: 10px 20px 10px 20px;
    }

    .system-report-tag > ul {
        display: flex;
        flex-wrap: wrap;
        gap: 3px;
        z-index: 1;
        list-style: none;
        padding: 0;
    }

    .system-report-tag > ul > li{
        font-size: 13px;
        padding: 1px 8px;
        margin-right: 10px;
        background-color: rgb(233, 232, 232);
        color: rgb(87, 86, 86);
        text-align: center;
        border-radius: 3px;
    }

    .system-report-prologue {
        width: 95%;
        margin: 10px 20px 10px 20px;
    }

    .system-report-prologue > span {
        font-size: 13px;
        color: #575757;
    }

    /* 
    * 各記事のスタイル : 終了
    */

/*
 * 詳細画面(PC)
*/
    .report-parent {
        width: 100%;
        overflow-y: auto;
        margin-top: 85px;
    }

    .report-content {
        width: 100%;
        min-width: 700px;
        height: fit-content ;
        margin-top: 30px;
        padding-bottom: 30px;
        display: flex;
        padding-left:0;
        justify-content: center;
    }

    /* 記事の内容 */
    .report-content-area{
        width: 60vw;
        min-width: 450px;
        max-width: 1000px;
        /*height: 100%;*/
        height: fit-content;
        min-height: 800px;
        text-align: center;
    }

    .report-content-thumbnail{
        width: 96%;
        object-fit: cover;
        text-align: center;
        object-fit: cover;          /* 画面の縦横比で小さい方に合わせて画面全体表示 */
        object-position: center;       /* 図が見切れる場合は、中央に残す */
        border-radius: 9px;
    }



    /* 
    * 記事のタイトル : 開始
    */
    .report-content-title-area {
        border-bottom: 1px solid;
        margin-bottom: 11px;
        border-width: 3px;
        border-color: #777664;
    }

    .report-content-title {
        width: 96%;
        text-align: left;
        padding: 15px 0px 0px 20px;
        margin: 0;
        font-size: 25px;
        color: #383838;
    }

    /* 
    * 記事のタイトル : 終了
    */



    /* 
    * 記事のサブ要素 : 開始
    */
    .report-content-sub{    
        width: 95%;
        color: #4e4e4e;
        font-size: 18px;
    }

    .report-content-sub-views-datetime {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .report-content-sub-views {
        padding-left: 40px;
    }

    .report-content-sub-views > p {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .report-content-sub-datetime {
        align-items: center;
        display: flex;
    }

    .report-content-sub-datetime > p {
        margin-top: 0;
        margin-bottom: 10px;
        padding-right: 10px;
    }

    .report-content-sub-datetime > time {
        margin-top: 0;
        margin-bottom: 10px;
    }

    /* 
    * 記事のサブ要素 : 終了
    */



    /* 
    * 記事の本文 : 開始
    */
    .report-content-composition-area {
        width: 100%;
        padding: 10px 20px;
        text-align: left;
        color: #383838;
    }

    .report-content-composition-area > h1 {
        font-size: 25px;
    }

    .report-content-composition-area > h1:nth-child(n+2) {
        padding-top: 15px;
    }

    .report-content-composition-area > p {
        width: 95%;
        font-size: 16px;
        word-break:break-all;
        margin: 0px;
        padding: 5px 0px;
    }

    .report-content-composition-area img {
        width: 80%;
        padding: 0% 10%;
    }

    .report-content-composition-area pre {
        background-color: #535246;
        margin: 20px 15px;
        padding: 10px;
        color: white;
        border-radius: 10px;
        white-space: pre-wrap;
    }

    /* 
    * 記事の本文 : 終了
    */



    /* 
    * 記事のもくじ : 開始
    */
    .report-index-area {
        height: auto;   /*スクロール時固定用*/

    }

    .report-index-area-inner {
        width: 20vw;
        min-width: 330px;
        max-width: 500px;
        min-height: 300px;  /*スクロール時固定用*/
        background-color: #f3f1df;
        border-radius: 10px;
        margin-left: 2vw;
        text-align: center;
        top: 30px;         /*スクロール時固定用*/
        position: sticky;   /*スクロール時固定用*/
        display: table;
    }

    .report-index-area-inner > h2 {
        height: 45px;
        margin: 0 0;
        color: rgb(255, 255, 255);
        background-color: #535246;
        width: 100%;
        border-color: rgb(224 219 176);
        border-radius: 5px 5px 0px 0;
        line-height: 45px;
    }

    .report-index-area-inner > ul {
        padding-left: 1vw;
        list-style: none;
    }

    .report-index-area-inner > ul > li{
        padding-left: 10px;
        padding-bottom: 10px;
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        color: #6b6a5d;
    }

    .report-index-area-inner > ul > li> a{
        text-decoration: none;
    }

    /* 表示していない章の目次 */
    .report-index-not-selected {
        color: #6b6a5d;
    }
    /* 表示を行った章の目次の色 */
    .report-index-not-selected:visited{
        color: #6b6a5d;
    }

    /* 表示中の章の目次 */
    .report-index-selected {
        font-size: 20px;
        /*color: #302f2d;*/
        color: #fdad15;
        font-weight: bold;
    }

    /* 
    * 記事のもくじ : 終了
    */

    /*
     * 記事が表示できない場合のレイアウト : 開始
    */

    /* コメント用フォントの追加 */
    @font-face {
        font-family: 'bestone';
        src: url('../fonts/BestTen-CRT.otf') format('opentype');
    }

    .report-not-found {
        height: 80vh;
        width: 100%;
        display: flex;
        flex-direction: column; /* 子要素を縦に配置 */
        justify-content: center;
        align-items: center;
    }

    .report-not-found > span {
        margin-bottom: 25px;
        font-family: 'bestone';
        font-size: 30px;
        /*font-weight: bold;*/
        color: #6f513d;
    }
    
    .report-not-found > img {
        height: 65%;
    }

    /*
     * 記事が表示できない場合のレイアウト : 終了
    */
/* コメント用フォントの追加 */
@font-face {
    /* シンデレラボーイズ用 */
    font-family: 'DelaGothicOne';
    src: url('../fonts/DelaGothicOne-Regular.ttf') format('truetype');

    /*CLASSROOM用*/
    font-family: 'Remover';
    src: url('../fonts/Remover-mLoV2.ttf') format('truetype');
  }

.music-content {
    width: 100%;
    height: 100%;
    min-width:950px;
    min-height: 800px;
    top: 0;
    left: 0;
    /*position: absolute;         /* ヘッダーの後ろに重ねて表示 */
    justify-content: center;    /* 垂直方向の中央表示 */   
    overflow: hidden;           /* スクロール禁止 */
    z-index: 1;                 /* ヘッダーより後ろに描画 */
    /* 水平・垂直方向の中央揃え */
    display: flex;
    justify-content: center;
    align-items: center;
}

.mp4-background {
    width: 50%;
    height: 100%;
    object-fit: cover;          /* 画面の縦横比で小さい方に合わせて画面全体表示 */
    object-position: top;       /* 図が見切れる場合は、下から削るようにする */
}

.img-content {
    height: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.band-content {
    width: auto;
    height: 20vw;
    max-height: 400px;
    margin-right: 5vw;
    margin-left: 5vw;
    text-align: center;
}

.band-content-cinderellaboys > p {
    font-family: 'DelaGothicOne';
}

.band-content-classroom > p {
    font-family: 'Remover';
    letter-spacing: 3px
}

.band-content > img {
    width: 100%;
    height: 100%;
    border-radius:50%;
}

.band-content > div {
    display: flex;
    justify-content: center;
}

.band-content > div > p {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin: 2px 10px 0px 0;;
}

.band-content > div > img {
    width: 1.3rem;
}

/*
.band-content > p:after {
    padding: 0 0 0 0.2rem;
    display: inline-block;
    width: 1.3rem;
    content: url("../images/icon/icon_exlink.svg");
    
}
*/
/* コンテンツ全体 */
.contact-content{
    width: 100%;
    height: 100%;
    min-width: 700px;
    min-height: 700px;
    top: 0;
    left: 0;
    position: absolute;         /* ヘッダーの後ろに重ねて表示 */
    justify-content: center;    /* 垂直方向の中央表示 */   
    overflow: hidden;           /* スクロール禁止 */
    z-index: 1;                 /* ヘッダーより後ろに描画 */
    text-align: center;
}

/* 背景画像 (PC) */
.contact-content > img {
    width: 100%;
    height: 100%;
    object-fit: cover;          /* 画面の縦横比で小さい方に合わせて画面全体表示 */
    position: absolute;         /* 配置固定用 */
    bottom: 0;                     /* 配置固定用 */
    right: 0;                    /* 配置固定用 */
    z-index: 1;
}

.contact-content > div {
    background-color: #fcfae6c4;
    margin: 130px 0px 0px 5%;
    z-index: 100;
    position: fixed;
    width: 30%;
    height: 200px;
    border-radius: 10px;
    min-width: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-content > div > table {
    width: 90%;
    height: 80%;
    text-align: start;
    font-family: 'bestone';
    font-size: 18px;
    color: #525252;
}

/* コメント用フォントの追加 */
@font-face {
    font-family: 'bestone';
    src: url('../fonts/BestTen-CRT.otf') format('opentype');
  }
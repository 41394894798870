/*
* ヘッダー用
*/

/* ヘッダー全体 (共通) */
header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    position: relative;
}

/* ヘッダーコンテンツ用 (共通) */
.header-container-common {
    height: 85px;
    width: 100%;
    justify-content: center;
    position: fixed;    /* 上部固定用 */
    top: 0;             /* 上部固定用 */
    left: 0;            /* 上部固定用 */
}

/* ヘッダーコンテンツ用 (PC) */
.header-container-pc {
    background-color:rgba(255, 255, 255, 0.6);
    min-width: 800px;
}

.header-container-pc-clear {
    background-color:rgba(255, 255, 255, 0.6);
    min-width: 800px;
}

.header-container-pc-no-icon {
    min-width: 800px;
    display: flex;      /* 上部固定用 */
}

/* ヘッダーコンテンツ用 (SP) */
.header-container-sp {
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: space-between;
    position: relative;
    align-items: center;
}

.header-icon-parent-pc {
    width: 100%;
    display: flex;

}

.header-icon-parent-pc > a {
    width: 20%;
    display: flex;
    justify-content: center;
}

/*
 * ヘッダー : ロゴ用
 */
/* ロゴ画像用 (PC) */
.header-logo-pc {
    height: 70px;
    padding: 10px 20px 0px; /* 1つ目:上 2つ目:左右 3つ目:下*/
}

/* ロゴ画像用 (SP) */
.header-logo-sp {
    height:45px;
    padding: 10px 20px 10px; /* 1つ目:上 2つ目:左右 3つ目:下*/
}

/*
* ヘッダー : メニュー用
*/
/* ナビゲーション (PC) */
.header-navi-pc {
width: 40%;
list-style: none;
display: flex;
}
.header-navi-pc > ul > li{
    text-align: center;
}

/* ナビゲーションのリスト管理 (PC) */
.header-navi-pc ul {
width: 100%;
height: 100%;
list-style: none;
display: flex;
padding-left: 0;
margin: 0px 0 0px;
padding: 12px 0 0px;
}

.header-navi-pc-ul-left {
    justify-content: flex-end;
    padding-right: 5% !important;
}

.header-navi-pc-ul-right {
    justify-content: flex-start;
    padding-left: 5% !important;
}

/* ナビゲーションのリスト要素 (PC) */
.header-navi-pc li {
height: 100%;
margin: 0 8%;
width: 60px;
}

/* ナビゲーションのリスト要素のリンク (PC) */
.header-navi-pc a{
text-decoration: none;
color: #8b8b8b;
font-size: 15px;
}

.header-navi-sp {
    height: 80%;
    width: auto;
    margin-left: auto;
    padding: 5px 15px 5px;
}

/*
* ヘッダー : メニューアイコン用 (PC)
*/

.navi-pc-item-common a::before {
    width: 2.3rem;
    height: 40px;
    content: "";
    background-size: contain;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
}
/* アイコン: system */
.navi-pc-item-system-selected a::before{
    background-image: url("../images/icon/icon_system_selected.svg");
    color: #fdad15;
}
.navi-pc-item-system-not-selected a::before{
    background-image: url("../images/icon/icon_system.svg");
}

/* アイコン: paint */
.navi-pc-item-paint-selected a::before{
    background-image: url("../images/icon/icon_paint_selected.svg");
    color: #fdad15;
}
.navi-pc-item-paint-not-selected a::before{
    background-image: url("../images/icon/icon_paint.svg");
}

/* アイコン: design */
.navi-pc-item-design-selected a::before{
    background-image: url("../images/icon/icon_design_selected.svg");
    color: #fdad15;
}
.navi-pc-item-design-not-selected a::before{
    background-image: url("../images/icon/icon_design.svg");
}

/* アイコン: music */
.navi-pc-item-music-selected a::before{
    background-image: url("../images/icon/icon_music_selected.svg");
}
.navi-pc-item-music-not-selected a::before{
    background-image: url("../images/icon/icon_music.svg");
}

/* アイコン: zine */
.navi-pc-item-zine-selected a::before{
    background-image: url("../images/icon/icon_zine_selected.svg");
}
.navi-pc-item-zine-not-selected a::before{
    background-image: url("../images/icon/icon_zine.svg");
}


/* アイコン: contact */
.navi-pc-item-contact-selected a::before{
    background-image: url("../images/icon/icon_contact_selected.svg");
}
.navi-pc-item-contact-not-selected a::before{
    background-image: url("../images/icon/icon_contact.svg");
}

/*
 * ヘッダー : システムページの検索・および表示形式変更用
*/
.header-navi-parent-pc {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
    top: 85px;
    position: fixed;
}

.header-navi-parent-pc > div {
    width: 90%;
    display: flex;
}

/* 検索入力フォーム : 全体用 */
.header-navi-form {
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
}

/* 検索入力フォーム : 入力フォーム用 */
.header-navi-form-input {
    width: 30vw;
    max-width: 350px;
    height: 33px;
    border: 1px solid;
    border-radius: 10px;
    border-color: #d4d4d4;
    background-color: #d4d4d4;
}

/* 検索入力フォーム : 検索ボタン用 */
.header-navi-form-submit {
    height: 28px;
    margin-left: 10px;
    margin-top: 3px;
}

/* 表示形式 : 全体用 */
.header-navi-display-format {
    width: 80vw;
    height: 30px;
    text-align: right;
    margin-top: 3px;
    margin-bottom: 3px;
    /*margin-right: 10px;*/
    display: flex;
    justify-content: flex-end;
}

/* 表示形式 : 文字用 */
.header-navi-display-format > span {
    font-size: 16px;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #626262;
}

/* 表示形式 : 切り替えボタン用 */
.header-navi-display-format > a > img {
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
}
/*
 * TOP画面用
 */

/* コンテンツ全体 */
.top-content{
    width: 100%;
    height: 100%;
    min-width: 700px;
    min-height: 700px;
    top: 0;
    left: 0;
    position: absolute;         /* ヘッダーの後ろに重ねて表示 */
    justify-content: center;    /* 垂直方向の中央表示 */   
    overflow: hidden;           /* スクロール禁止 */
    z-index: 1;                 /* ヘッダーより後ろに描画 */
    text-align: center;
}

/* TOP画像 (PC) */
.top-content > img {
    width: 100%;
    height: 100%;
    object-fit: cover;          /* 画面の縦横比で小さい方に合わせて画面全体表示 */
    object-position: top;       /* 図が見切れる場合は、下から削るようにする */
    position: absolute;         /* 配置固定用 */
    top: 0;                     /* 配置固定用 */
    left: 0;                    /* 配置固定用 */
}

#top-image {
    z-index: 0;
}

#top-image-action {
    z-index: 1;
    visibility: hidden;
}

#top-image-selected {
    z-index: 2;
    visibility: hidden;
}

/* コメント用フォントの追加 */
@font-face {
    font-family: 'bestone';
    src: url('../fonts/BestTen-CRT.otf') format('opentype');
  }

/* TOP画面のテキスト 外枠(PC) */
.top-content > div {
    position: absolute;
    visibility: hidden;
    background-color: #e9ececd1;
    border: solid 6px #20c1c7;
    border-radius: 15px;
}

span {
    text-align: left;
}

/* TOP画面のテキスト(PC) */
/*
p {
    font-family: 'bestone';
    font-size: 23px;
    color: #525252;
    margin-left: 20px;
    margin-right: 20px;
}
*/
#text-content > p {
    font-family: 'bestone';
    font-size: 23px;
    color: #525252;
    margin: 20px 20px 15px 15px;
}

.content-link {
    position: absolute;
    z-index: 3;
}

/* TOP用のテキスト 外枠用アニメーション (PC)*/
@keyframes showTextArea {
    0% {
        overflow: hidden;
    }


    100% {
        overflow: visible;
    }
}
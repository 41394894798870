/*
 * ローディング画面用
 */

/* コンテンツ全体 */
.loading-content {
    background: #ffffff;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;         /* ヘッダーの後ろに重ねて表示 */
    display: flex;              /* 水平方向の中央表示 */
    align-items: center;        /* 水平方向の中央表示 */
    justify-content: center;    /* 垂直方向の中央表示 */   
    flex-direction: column;     /* 画像と文字を2行に分けて表示 */
    z-index: 100;
}

/* ローディング描画用コンテンツの表示用 */
.loading-loader {
    height: 100%;
    width: 100%;
}

/* ローディングの画像 */
.loading-loader > img {
    width: 100%;
    height: 100%;
    object-fit: cover;          /* 画面の縦横比で小さい方に合わせて画面全体表示 */
    object-position: center;       /* 図が見切れる場合は、下から削るようにする */
    position: absolute;         /* 配置固定用 */
    top: 0;                     /* 配置固定用 */
    left: 0;                    /* 配置固定用 */
}

/* ローディングの文字 */
.loading-loader > p {
    font-size: 20px;
}

/* 動作: 画面全体をフェードアウト */
.loading-content-action-feadout {
    transition-property: opacity; /* 効果の対象を指定 */
    transition-delay: 1s;         /* 効果の開始時間を指定 */
    transition-duration: 1s;      /* 効果が完了するまでの時間 */
    opacity: 0;                   /* 要素の府透明度を指定 */
    pointer-events: none;         /* クリックやタッチを無効化 */
}

/* 動作 : ローディング描画用コンテンツの表示用をフェードアウト */
.loading-loader-action-fadeout {
    transition-property: opacity; /* 効果の対象を指定 */
    transition-delay: 1s;         /* 効果の開始時間を指定 */
    transition-duration: 0.8s;      /* 効果が完了するまでの時間 */
    opacity: 0;                   /* 要素の府透明度を指定 */
    pointer-events: none;         /* クリックやタッチを無効化 */
}
/*
 * 一覧・詳細画面(PC)共通
*/
.zine-content {
    width: 100%;
    min-width: 950px;
    height: 100%;       /* スクロール時もくじ固定用 */
    min-height: 800px;
    background-color: rgb(224 219 176);
    position: static;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.zine-swiper-parent {
    width: 70%;
    height: 60%;
    margin-top: 125px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-slide {
    width: 60%;
}

.zine-swiper-link {
    width: 100%;
    height: 100%;
    text-decoration:none;
}

.zine-swiper-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.zine-swiper-content > img {
    width: fit-content;
    height: 85%;
    filter: opacity(50%);
}

.zine-swiper-content-img-target {
    filter: opacity(100%) !important;
}

.zine-swiper-content-title {
    width: 200%;
    margin-top: 20px;
    
}

.zine-swiper-content-title > h1 {
    font-size: 1.3rem;
    font-weight: 400;
    color: #444444;
}

.zine-explanation-area {
    width: 45%;
    min-width: 700px;
    height: 20%;
    background-color: rgba(255, 252, 224, 0.81);;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.zine-explanation-area-content {
    height: 60%;
    margin: 10px 20px;
    font-size: 15px;
    color: #0c0c0c;
    display: grid;
}

.zine-explanation-area-datetime {
    margin: 10px 15px 0px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #797979;
}

.zine-explanation-area-datetime > hr {
    width: 100%;
    margin: 3px 0px;
    background-color: #a5a5a5;
    height: 1px;
    border: 0px;
}


/*
 * ウィンドウ内ウィンドウ(モーダル)用
 */
/* モーダル表示のアニメーション用 */
.ReactModal__Content {
    opacity: 0;
}
/* モーダル表示のアニメーション用 */
.ReactModal__Content--after-open {
    opacity: 1;
}
/* モーダル表示のアニメーション用 */
.ReactModal__Content--before-close {
    opacity: 0;
}

.zine-modal-parent {
    width: 100%;
    height: 100%;
    display: flex;
}

.zine-modal-parent {
    width: 100%;
    height: 100%;
}

.zine-modal-img-parent {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.zine-modal-img-parent {
    width: 100%;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.zine-modal-img-parent > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.zine-modal-img-parent > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}


/*
.zine-modal-img-parent > img {
    height: auto;
    max-height: 100%;
    width: fit-content;
    max-width: 100%;
}

.zine-modal-img-parent > img {
    height: auto;
    max-height: 100%;
    width: fit-content;
    max-width: 100%;
}
*/
.zine-modal-img {
    height: fit-content !important; 
    max-height: 100%;
    width: fit-content !important;
    max-width: 100%;
}